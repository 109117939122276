.import-container {
    > .import-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .text-container {
            > h1 {
                @include respond-to(xs) {
                    font-size: $font_size_h3;
                }
            }
            > p {
                font-size: $font_size_h6;
                margin-bottom: $size_base_multiplier * 3;

                @include respond-to(xs) {
                    font-size: 15px;
                }
            }
        }

        > .ant-tabs {
            // @include respond-to(xs) {
            //     overflow-y: scroll;
            // }

            > .ant-tabs-nav {
                &::before {
                    border-bottom: none;
                }
                // Tabs Buttons
                > .ant-tabs-nav-wrap {
                    > .ant-tabs-nav-list {
                        > .ant-tabs-ink-bar-animated {
                            display: none;
                        }
                        > .ant-tabs-tab {
                            > .ant-tabs-tab-btn {
                                border: solid 1px $colors_secondary;
                                border-radius: $size_base_multiplier * 2;
                                border-color: $colors_secondary;
                                padding: $size_base_multiplier / 2 $size_base_multiplier * 2;
                                display: flex;
                                font-family: 'gotham-bold';
                                font-size: $font_size;

                                &:hover {
                                    color: $colors_secondary;
                                }
                            }
                            &.ant-tabs-tab-active {
                                > .ant-tabs-tab-btn {
                                    font-family: 'gotham-bold';
                                    background-color: $colors_secondary;
                                    color: $colors_bg;
                                    &:hover {
                                        color: $colors_bg;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .ant-form-item-control-input-content {
                > span {
                    display: flex;
                    @include respond-to(xs) {
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .ant-upload {
                        background-color: #f5f5f5;
                        border: solid 1px #d9d9d9;
                        border-radius: 2px;
                        margin-right: $size_base_multiplier;
                        height: $size_base_multiplier * 34;
                        max-width: $size_base_multiplier * 89;

                        @include respond-to(xs) {
                            margin: 0 0 $size_base_multiplier * 3 0;
                        }

                        .ant-upload-btn {
                            padding: 0;
                            .ant-upload-drag-container {
                                height: 100%;
                                padding: $size_base_multiplier;
                                @include respond-to(xs) {
                                    margin: 0 auto;
                                }
                                > .drag-icon {
                                    margin-bottom: $size_base_multiplier * 2;
                                    > i:before {
                                        font-size: $size_base_multiplier * 11;

                                        @include respond-to(xs) {
                                            font-size: $size_base_multiplier * 8;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ant-upload-list {
                        .ant-upload-span {
                            @include respond-to(xs) {
                                flex-wrap: nowrap;
                            }
                        }
                    }
                }
            }
            .ant-row {
                .ant-form-item-label {
                    padding: 0;
                    height: $size_base_multiplier * 5;
                }
                .ant-form-item-control {
                    .ant-select {
                        > .ant-select-selector {
                            &:hover {
                                border-color: $colors_secondary;
                                box-shadow: 0 0 0 2px #62626236;
                            }

                            .ant-select-selection-placeholder {
                                font-size: $font_size;
                                text-align: start;
                            }
                        }
                    }
                }
            }
            .bottom-container {
                margin-top: $size_base_multiplier * 5;
                .ant-row {
                    .ant-form-item-control-input-content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        > .bottom-text-container {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-bottom: $size_base_multiplier * 2;
                            > p {
                                text-align: center;
                                margin: 0;
                                font-size: 13px;
                                color: #8c8d8c;
                            }
                            > .link-container {
                                display: flex;
                                > p {
                                    margin: 0 $size_base_multiplier 0 0;
                                    font-size: 13px;
                                    color: #8c8d8c;
                                }
                                > .link {
                                    font-family: 'gotham-bold';
                                    color: $colors_secondary;
                                    text-decoration: underline;
                                    margin-right: $size_base_multiplier;
                                }
                            }
                        }
                        > .ant-btn {
                            display: flex;
                            align-items: center;
                            background-color: $colors_secondary;
                            border-color: $colors_secondary;
                            border-radius: 10px;
                            padding: $size_base_multiplier $size_base_multiplier * 5;
                            max-width: 300px;
                            &:disabled {
                                opacity: 0.5;
                            }

                            &:not(:disabled):hover {
                                background-color: #7a7f837a;
                                border-color: #7a7f837a;
                                > span {
                                    color: $colors_secondary;
                                }
                            }
                            > span {
                                color: $colors_bg;
                                font-family: 'gotham-bold';

                                @include respond-to(xs) {
                                    font-size: $font_size;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
