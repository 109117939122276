// -----------------------------------------------------------------
//                     c o l o r s
// -----------------------------------------------------------------
$colors_bg: #fff;
$colors_external_bg: #f6f6f6;
$colors_text: #333;
$colors_gray_shadow: transparentize(#63656c, 0.76);
$colors_secondary: #001e36;
// $colors_secondary: #f8f8f8;
$colors_third: #d9d9d9;
// $colors_third: #e8e8e8;
$colors_fourth: #8c8d8c;
$colors_silver: #cccccc;

// -----------------------------------------------------------------
//                     s i z e s
// -----------------------------------------------------------------
$size_base_multiplier: 8px;

// -----------------------------------------------------------------
//                     s h a d o w s
// -----------------------------------------------------------------
$button_shadow: 4px 4px 20px $colors_gray_shadow;

// -----------------------------------------------------------------
//                     f o n t  s i z e s
// -----------------------------------------------------------------
$font_size: 14px;
$font_size_h1: 26px;
$font_size_h2: 24px;
$font_size_h3: 22px;
$font_size_h4: 20px;
$font_size_h5: 18px;
$font_size_h6: 16px;

// -----------------------------------------------------------------
//                     l i n e   h e i g h t s
// -----------------------------------------------------------------
$line_height: 20px;
$line_height_h5: $font_size_h5 + 6;
$line_height_h6: $font_size_h6 + 6;

// -----------------------------------------------------------------
//                     f o n t  w e i g h t s
// -----------------------------------------------------------------
$font_light: 300;
$font_regular: 400;
$font_medium: 500;
$font_semi_bold: 600;

// -----------------------------------------------------------------
//                     b o x   s h a d o w
// -----------------------------------------------------------------

// -----------------------------------------------------------------
//                    b o r d e r s
// -----------------------------------------------------------------
