.home-container {
    > .home-title {
        margin-bottom: $size_base_multiplier * 9;

        @include respond-to(xs) {
            font-size: $font_size_h5;
        }
    }
    > .card-container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        @include respond-to(upto-sm) {
            flex-direction: column;
            align-items: center;
        }
        > .ant-card {
            border-radius: $size_base_multiplier;
            box-shadow: 0 $size_base_multiplier $size_base_multiplier * 3 0 #001e362e;
            margin-bottom: $size_base_multiplier * 4;

            @include respond-to(upto-sm) {
                margin-bottom: $size_base_multiplier * 3;
            }
            > .ant-card-body {
                padding: $size_base_multiplier * 3 $size_base_multiplier * 5 $size_base_multiplier *
                    5 $size_base_multiplier * 5;
                max-width: $size_base_multiplier * 51;

                @include respond-to(xs) {
                    max-width: $size_base_multiplier * 45;
                }

                > h2 {
                    @include respond-to(xs) {
                        font-size: $font_size_h5;
                    }
                }

                > p {
                    font-size: $font_size_h6;

                    @include respond-to(xs) {
                        font-size: $font_size;
                    }
                }

                > .ant-btn {
                    display: flex;
                    align-items: center;
                    background-color: $colors_secondary;
                    border-color: $colors_secondary;
                    border-radius: 10px;
                    padding: $size_base_multiplier $size_base_multiplier * 5;
                    max-width: 300px;
                    margin: 0 auto;
                    &:disabled {
                        opacity: 0.5;
                    }

                    &:hover {
                        background-color: #7a7f837a;
                        border: none;
                        > h6 {
                            color: $colors_secondary;
                        }
                    }
                    > h6 {
                        color: $colors_bg;
                        margin: 0;

                        @include respond-to(xs) {
                            font-size: $font_size;
                        }
                    }
                }
            }
        }
    }
}
