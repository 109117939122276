.error-handling-container {
    > p {
        font-size: $size_base_multiplier * 2;
    }
    > .tablelist {
        margin-top: $size-base-multiplier * 4;
    }
    .ant-table-container {
        margin: $size_base_multiplier * 5 0 $size_base_multiplier * 6 0;
        .ant-table-thead {
            .ant-table-cell {
                text-align: start;
                background-color: #fafafa;
                font-family: 'gotham-bold';
                font-size: $font_size_h6;
                @include respond-to(upto-md) {
                    font-size: $font_size;
                }
                &.error-type-column {
                    width: $size_base_multiplier * 33;
                }
                &.date-column,
                &.action-column {
                    width: $size_base_multiplier * 13;
                }
            }
        }
        .ant-table-tbody {
            .ant-table-cell {
                text-align: start;
                font-size: 15px;
                @include respond-to(upto-md) {
                    font-size: $font_size;
                }
            }
        }
    }
}
