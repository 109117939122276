@import './partials/fonts';
@import './variables';
@import './mixins/responsive';
@import './mixins/placeholder';
@import './mixins/clearfix';
@import './mixins/ellipsis';
@import './partials/loader';
@import './partials/signIn';
@import './partials/export';
@import './partials/header';
@import './partials/import';
@import './partials/home';
@import './partials/errorHandling';

html {
    margin: 0;
    padding: 0;
}

html,
body,
#root,
.marinelli-app {
    height: 100%;
}

.preline {
    white-space: pre-line;
}

iframe {
    display: none;
}

body.body {
    background: $colors_external_bg;
    font-weight: $font_light;
    font-size: $font_size;
    color: $colors_text;
    overflow: unset !important;

    * {
        font-family: 'Montserrat', sans-serif;

        &:focus {
            outline: none;
        }
    }

    .disabled {
        opacity: 0.7;
        pointer-events: none;
        user-select: none;
    }

    .img-responsive {
        width: auto;
        height: auto;
        max-width: 100%;
    }

    strong {
        font-weight: $font_semi_bold;
    }

    .center,
    .middle {
        display: flex;
        flex-direction: column;
    }
    .center {
        align-items: center;
    }
    .middle {
        height: 100vh;
        justify-content: center;
    }

    .marinelli-app {
        height: 100%;
        background-color: $colors_external_bg;
    }
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: $size_base_multiplier * 2;
        font-family: 'gotham-bold';
    }

    h1,
    .h1 {
        font-size: $font_size_h1;
    }

    h2,
    .h2 {
        font-size: $font_size_h2;
    }

    h3,
    .h3 {
        font-size: $font_size_h3;
    }

    h4,
    .h4 {
        font-size: $font_size_h4;
    }

    h5,
    .h5 {
        font-size: $font_size_h5;
        line-height: #{$line_height_h5};
    }

    h6,
    .h6 {
        font-size: $font_size_h6;
    }
}
