.marinelli-app {
    $margin: $size_base_multiplier * 3;
    padding: $margin $margin 0 $margin;
    .main-card {
        border-radius: $size_base_multiplier;
        box-shadow: 5px 8px 24px 5px $colors_external_bg;
        overflow: hidden;
        padding: $size_base_multiplier * 3;
        background-color: $colors_bg;
        min-height: calc(100vh - #{$margin * 2});

        @include respond-to(xs) {
            padding: $size_base_multiplier * 3 $size_base_multiplier * 2;
        }

        > .export-container {
            height: inherit;
            > h1 {
                font-size: $font_size_h2;

                @include respond-to(xs) {
                    font-size: $font_size_h3;
                }
            }
            > p {
                font-size: $font_size_h6;
                margin-bottom: $size_base_multiplier * 3;

                @include respond-to(xs) {
                    font-size: 15px;
                }
            }
            > .ant-tabs {
                height: inherit;

                @include respond-to(xs) {
                    overflow-y: scroll;
                }

                > .ant-tabs-nav {
                    &::before {
                        border-bottom: none;
                    }
                    // Tabs Buttons
                    > .ant-tabs-nav-wrap {
                        > .ant-tabs-nav-list {
                            > .ant-tabs-ink-bar-animated {
                                display: none;
                            }
                            > .ant-tabs-tab {
                                > .ant-tabs-tab-btn {
                                    border: solid 1px $colors_secondary;
                                    border-radius: $size_base_multiplier * 2;
                                    border-color: $colors_secondary;
                                    padding: $size_base_multiplier / 2 $size_base_multiplier * 2;
                                    display: flex;
                                    font-family: 'gotham-bold';
                                    font-size: $font_size;

                                    &:hover {
                                        color: $colors_secondary;
                                    }
                                }
                                &.ant-tabs-tab-active {
                                    > .ant-tabs-tab-btn {
                                        font-family: 'gotham-bold';
                                        background-color: $colors_secondary;
                                        color: $colors_bg;
                                        &:hover {
                                            color: $colors_bg;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .ant-input:focus,
                .ant-input:hover,
                .ant-input::selection,
                .ant-select-selector:hover {
                    border-color: $colors_secondary;
                    box-shadow: 0 0 0 2px #62626236;
                }
                > .ant-tabs-content-holder {
                    // Export Data content
                    .ant-form-vertical {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: $size_base_multiplier * 55;
                        > .date-container {
                            .ant-form-item-label {
                                padding-bottom: 0;

                                @include respond-to(xs) {
                                    max-height: $size_base_multiplier * 5;
                                }
                            }

                            .ant-picker-input {
                                > input {
                                    font-size: $font_size;
                                    &::placeholder {
                                        font-size: $font_size;
                                    }
                                }
                            }
                            .ant-picker:hover,
                            .ant-picker-focused {
                                border-color: $colors_secondary;
                                box-shadow: 0 0 0 2px #62626236;

                                .ant-picker-active-bar {
                                    background-color: $colors_secondary;
                                }
                            }
                        }
                        .inputs-container {
                            display: grid;
                            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                            grid-gap: 24px 16px;
                            margin-bottom: $size_base_multiplier * 5;

                            @include respond-to(from-lg) {
                                width: 70%;
                            }

                            > .ant-row {
                                display: flex;
                                flex-direction: column !important;
                                flex-wrap: nowrap;
                                margin-bottom: 0;

                                .ant-form-item-label {
                                    padding-bottom: 0;

                                    @include respond-to(xs) {
                                        max-height: $size_base_multiplier * 5;
                                    }
                                }
                                .ant-input {
                                    width: $size_base_multiplier * 26;
                                    &::placeholder {
                                        font-size: $font_size;
                                    }
                                }
                                .ant-select {
                                    > .ant-select-selector {
                                        // &:hover {
                                        //     border-color: $colors_secondary;
                                        //     box-shadow: 0 0 0 2px #62626236;
                                        // }
                                        .ant-select-selection-placeholder {
                                            font-size: $font_size;
                                        }
                                    }
                                }
                            }
                        }
                        .bottom-container {
                            display: flex;
                            flex-direction: column;
                            text-align: center;
                            > .ant-row {
                                .ant-form-item-control-input-content {
                                    > p {
                                        font-size: 13px;
                                        margin-bottom: $size_base_multiplier * 4;
                                        white-space: pre-line;
                                        color: #8c8d8c;
                                    }
                                    > .ant-btn {
                                        background-color: $colors_secondary;
                                        border-color: $colors_secondary;
                                        border-radius: 10px;
                                        padding: $size_base_multiplier $size_base_multiplier * 5;
                                        display: flex;
                                        margin: 0 auto;

                                        &:disabled {
                                            opacity: 0.5;
                                        }
                                        &:not(:disabled):hover {
                                            background-color: #7a7f837a;
                                            border-color: #7a7f837a;
                                            > span {
                                                color: $colors_secondary;
                                            }
                                        }
                                        > span {
                                            font-family: 'gotham-bold';
                                            color: $colors_bg;

                                            @include respond-to(xs) {
                                                font-size: $font_size;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // Export Quote content
                    .export-quote-container {
                        > .ant-form-item {
                            flex-direction: column !important;
                            flex-wrap: nowrap;

                            .ant-form-item-label {
                                padding-bottom: 0;

                                @include respond-to(xs) {
                                    max-height: $size_base_multiplier * 5;
                                }
                            }
                            .ant-input {
                                width: $size_base_multiplier * 46;
                                @include respond-to(xxs) {
                                    width: $size_base_multiplier * 42;
                                }
                                &::placeholder {
                                    font-size: $font_size;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.body {
    .ant-picker-dropdown-range {
        @include respond-to(xxs) {
            width: $size_base_multiplier * 35;
            top: $size_base_multiplier * 55;
            left: $size_base_multiplier * 5;
        }

        .ant-picker-panels {
            @include respond-to(xxs) {
                flex-direction: column;
            }

            .ant-picker-content {
                .ant-picker-cell-today {
                    .ant-picker-cell-inner {
                        &::before {
                            border: 1px solid $colors_secondary;
                        }
                    }
                }
                .ant-picker-cell-range-start,
                .ant-picker-cell-range-end {
                    &::before {
                        background-color: transparent;
                    }
                    .ant-picker-cell-inner {
                        background-color: $colors_secondary;
                    }
                }
                .ant-picker-cell-in-range::before {
                    background-color: #001e361a;
                }
            }
        }
    }
}
