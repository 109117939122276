.header-container {
    margin: $size_base_multiplier * 2 0 $size_base_multiplier * 3 0;
    > .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 $size_base_multiplier * 2 0;

        > p {
            margin-bottom: 0;
            font-size: $font_size_h6;

            @include respond-to(xs) {
                font-size: 15px;
            }
        }
        > .ant-btn {
            span {
                font-size: $font_size_h6;
                text-decoration: underline;
                color: $colors_text;

                @include respond-to(xs) {
                    font-size: 15px;
                }
            }
        }
    }
}
