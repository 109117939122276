.ant-card {
    &#main {
        background-color: $colors_external_bg;
        > .ant-card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            min-height: 100vh;

            > .card-header {
                display: flex;
                justify-content: space-between;
                width: $size_base_multiplier * 24;
                > .ant-image {
                    > img {
                        width: auto;
                        height: auto;
                        max-width: $size_base_multiplier * 9;
                        max-height: $size_base_multiplier * 11;
                    }
                }
            }

            > .ant-form {
                background-color: $colors_bg;
                padding: $size_base_multiplier * 5 $size_base_multiplier * 4;
                width: $size_base_multiplier * 50;
                height: $size_base_multiplier * 48;
                border-radius: $size_base_multiplier;

                > .ant-row {
                    > .ant-col {
                        > .input-container {
                            > .ant-form-item-label {
                                padding-bottom: 0;
                                h6 {
                                    margin-bottom: 0;
                                }
                            }
                            > .ant-form-item-control {
                                border-radius: 8px;

                                .input {
                                    border-radius: 5px;
                                    &:hover {
                                        border-color: $colors_secondary;
                                        box-shadow: 0 0 0 2px #62626236;
                                    }
                                    &:focus {
                                        border-color: $colors_secondary;
                                        box-shadow: 0 0 0 2px #62626236;
                                    }
                                }
                            }
                        }

                        .submit-btn {
                            display: flex;
                            justify-content: center;
                            .ant-btn {
                                background-color: $colors_secondary;
                                width: $size_base_multiplier * 27;
                                border-radius: 10px;
                                border-color: $colors_secondary;
                                display: flex;
                                justify-content: center;

                                &:disabled {
                                    opacity: 0.5;
                                }
                                &:hover {
                                    background-color: #7a7f837a;
                                    border: none;
                                    > span {
                                        color: $colors_secondary;
                                    }
                                }

                                > span {
                                    font-family: 'gotham-bold';
                                    color: $colors_bg;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
